<script lang="ts">
    import { Accordion } from 'svelte-accessible-accordion'

    export let multiselect = false
</script>

<Accordion {multiselect}>
    <slot />
</Accordion>

<style global lang="postcss">
    [data-accordion] {
        --y-sp: theme('spacing.4');

        padding-left: 0;
        list-style: none;
    }
</style>
