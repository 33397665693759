<script lang="ts">
    import { AccordionItem } from 'svelte-accessible-accordion'

    export let expanded = false
    export let disabled = false
    export let title: string
</script>

<AccordionItem {disabled} {expanded} {title}>
    <slot />
</AccordionItem>

<style global lang="postcss">
    /* stylelint-disable selector-no-qualifying-type */
    [data-accordion-item] {
        position: relative;
        margin: 0;
        border-radius: theme('borderRadius.DEFAULT');
    }

    [data-accordion-item]:not(:last-child) {
        border-bottom: solid #d8e0e4 1px;
    }

    [data-accordion-item]:hover {
        border-color: #afedb9;
    }

    [data-accordion-item] button::before {
        content: '+';
        position: absolute;
        top: calc(var(--y-sp) * 0.3);
        left: 1rem;
        font-size: theme('fontSize.3xl');

        @media screen(md) {
            top: calc(var(--y-sp) * 0.5);
        }
    }

    [data-accordion-item]:hover button::before {
        color: #1e9e31;
    }

    [data-accordion-item]:has(button[aria-expanded='true']) {
        background-color: #fff;
    }

    [data-accordion-item] button {
        width: 100%;
        padding: var(--y-sp) 1rem var(--y-sp) 3rem;
        margin: 0;
        font-size: theme('fontSize.base');
        text-align: left;
        border: 0;
        border-radius: 0;
        cursor: pointer;

        @media screen(md) {
            --y-sp: 1.5rem;

            padding: var(--y-sp) 1rem var(--y-sp) 3rem;
        }
    }

    [data-accordion-item] button[aria-expanded='true'] {
        padding-bottom: calc(var(--y-sp) * 0.5);
    }

    [data-accordion-item] button[aria-expanded='true']::before {
        content: '−';
    }

    [data-accordion-item] button[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    [data-accordion-item] [role='region'] {
        padding: 0 1rem var(--y-sp) 3rem;
        color: #718d9b;

        @media screen(md) {
            padding: 0 1rem calc(var(--y-sp) * 1.8) 3rem;
        }
    }
</style>
